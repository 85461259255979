import { Container, Link, Typography } from '@mui/material'

const Footer = () => {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{ py: 2, alignSelf: 'center' }}
    >
      <Link
        href="https://collectiveform.io"
        target="_blank"
        rel="noreferrer"
        underline="hover"
        color="inherit"
      >
        <Typography variant="body1" fontWeight="md" textAlign="center">
          Made by Collective Form
        </Typography>
      </Link>
    </Container>
  )
}

export default Footer
