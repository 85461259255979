import { Box, Button } from '@mui/material'
import SignUpModal from './SignUpModal'
import { useState } from 'react'

const CallToAction = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Button
          variant="contained"
          disableElevation
          onClick={handleOpenModal}
          aria-label="Sign Up"
          sx={{
            px: 3,
            py: 2,
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Sign Up for Early Access
        </Button>
      </Box>

      <SignUpModal open={modalOpen} onClose={handleCloseModal} />
    </>
  )
}

export default CallToAction
