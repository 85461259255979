import Hero from './components/Hero'
import Footer from './components/Footer'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { Box, Stack } from '@mui/material'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'

// Updated environment variable on 2023-06-23
// No code changes made
const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID

if (measurementId) {
  ReactGA.initialize(measurementId)
}

const App = () => {
  // Send pageview event to Google Analytics
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Stack spacing={3}>
          <Hero />
          <Footer />
        </Stack>
      </Box>
    </ThemeProvider>
  )
}

export default App
