import { createTheme } from '@mui/material/styles'
import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'
import '@fontsource/ibm-plex-sans/400-italic.css'
import '@fontsource/ibm-plex-sans/500-italic.css'
import '@fontsource/ibm-plex-sans/600-italic.css'
import '@fontsource/ibm-plex-sans/700-italic.css'

const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      fontSize: '2.75rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.75)',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    button: {
      fontSize: '1.25rem',
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 12,
  },
  spacing: 8,
  palette: {
    mode: 'light',
    contrastThreshold: 4.5,
    primary: {
      main: '#2F2AD5',
    },
    secondary: {
      main: '#1677d9',
    },
    background: {
      default: '#F7F7F8', // your custom default background color
      paper: '#FFF', // your custom paper background color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          bottom: 24,
        },
      },
    },
  },
})

export default theme
