import { Alert, Snackbar } from '@mui/material'

interface SimpleSnackbarProps {
  open: boolean
  message: string
  onClose: () => void
}

const SimpleSnackbar = ({ open, message, onClose }: SimpleSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="success">{message}</Alert>
    </Snackbar>
  )
}

export default SimpleSnackbar
