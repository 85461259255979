import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import Airtable from 'airtable'
import LoadingButton from '@mui/lab/LoadingButton'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { useState } from 'react'
import SimpleSnackbar from './common/SimpleSnackbar'

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_TOKEN || '',
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID || '')

interface SignUpModalProps {
  open: boolean
  onClose: () => void
}

const SignUpModal = ({ open, onClose }: SignUpModalProps) => {
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const name = event.target[0].value
    const email = event.target[1].value
    const organization = event.target[2].value
    const role = event.target[3].value

    try {
      await base('Sign Ups').create([
        {
          fields: {
            Name: name,
            Email: email,
            Organization: organization,
            Role: role,
          },
        },
      ])

      console.log('Record added to Airtable')
      event.target.reset() // Clear the form
      onClose() // Close the modal
      setOpenSnackbar(true) // Open the snackbar
    } catch (error) {
      console.error('Error adding record to Airtable:', error)
    }
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ p: 3 }}>Sign up for Insite</DialogTitle>
          <DialogContent sx={{ p: 3 }}>
            <Stack spacing={2}>
              <DialogContentText sx={{ mb: 1 }}>
                Please enter your name and email address below to sign up for
                Insite early access.
              </DialogContentText>
              <TextField
                autoFocus
                id="name"
                label="Full Name"
                type="text"
                fullWidth
                variant="standard"
                placeholder="Fumihiko Maki"
                required
              />
              <TextField
                id="name"
                label="Work Email"
                type="email"
                fullWidth
                variant="standard"
                placeholder="support@collectiveform.io"
                required
              />
              <TextField
                id="name"
                label="Organization"
                type="text"
                fullWidth
                variant="standard"
                placeholder="Maki and Associates"
              />
              <TextField
                id="name"
                label="Role"
                type="text"
                fullWidth
                variant="standard"
                placeholder="Senior Urban Designer"
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button onClick={onClose} aria-label="Cancel" color="error">
              <Typography variant="body1">Cancel</Typography>
            </Button>
            <LoadingButton
              loadingPosition="end"
              endIcon={<CheckCircle />}
              type="submit"
              aria-label="Submit"
              variant="contained"
              disableElevation
            >
              <Typography variant="body1" color="white">
                Submit
              </Typography>
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <SimpleSnackbar
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message="Sign up successful!"
      />
    </>
  )
}

export default SignUpModal
