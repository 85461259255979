import { Typography, Box, Stack } from '@mui/material'
import InsiteLogo from './InsiteLogo'
import HeroImage from './HeroImage'
import CallToAction from './CallToAction'

const Hero = () => {
  return (
    <Box
      component="section"
      sx={{
        textAlign: 'center',
        py: 1,
        px: 3,
      }}
    >
      <InsiteLogo />
      <Stack spacing={5}>
        <Typography variant="h1">Present with Insite</Typography>
        <Typography variant="h2" maxWidth="sm" alignSelf="center">
          Interactive community engagement for urban design and planning
        </Typography>
        <CallToAction />
        <HeroImage />
      </Stack>
    </Box>
  )
}

export default Hero
