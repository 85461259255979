import { Container } from '@mui/material'
import featureImage from '../assets/images/featureimage.png'

const HeroImage = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        maxHeight: 800,
        p: 0,
        alignSelf: 'center',
      }}
    >
      <img
        src={featureImage}
        alt="Insite"
        loading="lazy"
        width="100%"
        style={{
          borderRadius: '16px',
          border: '2px solid #eaeaea',
          boxSizing: 'border-box',
        }}
      />
    </Container>
  )
}

export default HeroImage
