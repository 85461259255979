import { Container } from '@mui/material'
import { ReactComponent as Logo } from '../assets/images/insitelogo.svg'

const InsiteLogo = () => {
  return (
    <Container>
      <Logo width={120} height={80} aria-label="Insite Logo" />
    </Container>
  )
}

export default InsiteLogo
